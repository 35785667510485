// app global css in SCSS form
.flex-break
  flex: 1 0 100% !important
.row
  .flex-break
    height: 0 !important
.column
  .flex-break
    width: 0 !important

.body--dark
    --q-primary: #114b86
    --q-secondary: #17645d
    --q-accent: #5a1766
    --q-positive: #116324
    --q-negative: #66010c
    --q-info: #196474
    --q-warning: #8b6f20
